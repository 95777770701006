import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "South Korea",
  "subtitle": "2013",
  "category": "Asia"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Gyeran-ppang"
        }}>{`계란빵 (Gyeran-ppang)`}</a>{` - Egg bread`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Tteokbokki"
        }}>{`떡볶이 (Tteokbokki)`}</a>{` - Spicy rice cakes from street cart is the best`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Mandu_(food)"
        }}>{`만두 (Mandoo)`}</a>{` - Dumplings`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Bukkumi"
        }}>{`부꾸미 (Bukkumi)`}</a>{` - Try one with red bean paste`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Sundae_(sausage)"
        }}>{`순대 (Soondae)`}</a>{` - Blood sausages`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Yangnyeom_chicken"
        }}>{`양념치킨 (Yangnyeom-chikin)`}</a>{` - Sweet, sour, & spicy Korean fried chicken`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Fishcake"
        }}>{`어묵국 (Eomuk-guk)`}</a>{` - Korean Fishcake Stew, similar to Japanese oden`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Jwipo"
        }}>{`쥐포 (Jwipo)`}</a>{` - Traditional Korean pressed fish jerky`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Hotteok"
        }}>{`호떡 (Hotteok)`}</a>{` - Sweet Korean pancake. Try the Green tea version too.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Tornado_potato"
        }}>{`회오리 감자`}</a>{` - Tornado potato stick`}</li>
      <li parentName="ul">{`오징어통구이 (Ojingeo-tonggui) - Spicy grilled squid`}</li>
      <li parentName="ul">{`오징어튀김 (Ojingeo-twigim) - Fried dried squid`}</li>
      <li parentName="ul">{`Tall ice cream - Something like 32cm! (Can be had in Myeongdong for sure)`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Jjimjilbang"
        }}>{`찜질방 (Jimjilbang)`}</a>{` - Traditional Korean bathhouse`}</li>
    </ul>
    <h2 {...{
      "id": "서울-seoul"
    }}>{`서울 (Seoul)`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4LSmtqyqUrFtptLV7"
        }}>{`명동야시장`}</a>{` - Myeongdong night market sells lots of street food`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QeE1iZnhjTtS9Zma8"
        }}>{`노량진수산시장`}</a>{` - Noryangjin Fish Market`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.hollys.co.kr/"
        }}>{`Holly’s`}</a>{` - Coffee franchise`}</li>
    </ul>
    <h4 {...{
      "id": "홍대-hongdae"
    }}>{`홍대 (Hongdae)`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/PfhqZzdd8sN2Emz36"
          }}>{`콩불 (Kongbul)`}</a>{` - Stir-fry`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/TL9mo1aNhXCXJb7t7"
          }}>{`Thanks Nature Cafe`}</a>{` - Sheep cafe!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/JHfY9tZnZApB3Zbd8"
          }}>{`누나홀닭 (Noonaholdak)`}</a>{` - Fried chicken`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://maps.app.goo.gl/dwUqhDd927SWYJC27"
          }}>{`신당동떡볶이골목`}</a>{` - Sindang-dong Tteokbokki Town`}</p>
        <ul parentName="li">
          <li parentName="ul">{`This whole area serves Tteokbokki like shared hot pot`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/Ew1af77CSHB8jvfeA"
            }}>{`마복림할머니 막내아들네`}</a>{` - Liked this place`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QbKWaDGhEdmFAzBeA"
        }}>{`반포대교 (Banpo Bridge)`}</a>{` - Looks great at night with lights`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/umeVYWKJdifvai2v7"
        }}>{`서울타워 (Seoul Tower)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Cable car had a long wait`}</li>
          <li parentName="ul">{`There is an `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/VUb3BKuesPnAEZ36A"
            }}>{`area full of locks`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qAwjMHCWwRM38j98A"
        }}>{`올림픽공원 (Olympic Park)`}</a>{` - Do the stamp tour. Lonely Tree had the best stamp.`}</li>
    </ul>
    <h4 {...{
      "id": "seoul-palace-pass"
    }}>{`Seoul Palace Pass`}</h4>
    <ul>
      <li parentName="ul">{`Closed on Monday`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/Hj62xgoVewzh1bU4A"
            }}>{`창덕궁 (Changdeokgung)`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://maps.app.goo.gl/qZJUhczHj6so9ncCA"
                }}>{`창덕궁 후원`}</a>{` - Changdeokgung Secret Garden offers a guided tour`}</li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/ukXHbGahcC2TXfcF9"
            }}>{`덕수궁 (Deoksugung Palace)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/pvvehYaaDSeDK5cLA"
            }}>{`창경궁 (Changgyeonggung Palace)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Closed on Tuesday`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://goo.gl/maps/tYZfTnSQU6tE399w5"
            }}>{`경복궁 (Gyeongbokgung Palace)`}</a>
            <ul parentName="li">
              <li parentName="ul">{`You can take a photo of the `}<a parentName="li" {...{
                  "href": "https://maps.app.goo.gl/SxgYy6ELKZCmApQA6"
                }}>{`Blue House`}</a>{` from here`}</li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://maps.app.goo.gl/4vLXoa5vaJCFXsu49"
                }}>{`National Folk Museum of Korea`}</a>{` included with admission`}</li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://maps.app.goo.gl/hDYeyTWCtRA5HmQz6"
                }}>{`National Palace Museum of Korea`}</a>{` included with admission`}</li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/yMdY2Zo31i13iGxw9"
            }}>{`종묘 (Jongmyo Shrine)`}</a></li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "shows"
    }}>{`Shows`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/9Qkb4cnfJcnZp3899"
        }}>{`NANTA`}</a>{` - Non-verbal comedy show based on cooking`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/YKG7TcMPMK7Ku2Pa9"
        }}>{`JUMP`}</a>{` - Non-verbal comedy show based on martial arts`}</li>
    </ul>
    <h4 {...{
      "id": "shopping"
    }}>{`Shopping`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AJXoJh5v9sUc3Z6J8"
        }}>{`동대문종합시장`}</a>{` - Dongdaemun market`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qu65t3D5rTxs91428"
        }}>{`고투몰 (Gotomall)`}</a>{` - Underground shopping mall`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/8VLhY7jndfp7XtAJA"
        }}>{`명동거리`}</a>{` - Myeondong shopping area sells all the cosmetics`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/sYSafScVieyFdZzw9"
        }}>{`Starfield Coex Mall`}</a></li>
    </ul>
    <h2 {...{
      "id": "수원-suwon"
    }}>{`수원 (Suwon)`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/vkJ6vJ4mZReCqJ976"
        }}>{`수원화성 (Suwon Hwaseong)`}</a>
        <ul parentName="li">
          <li parentName="ul">{`About 2-3 hours to circle on foot but there's a trolly alternative`}</li>
          <li parentName="ul">{`Do the stamp tour! I believe it was 6 stops`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "제주-jeju"
    }}>{`제주 (Jeju)`}</h2>
    <ul>
      <li parentName="ul">{`Rent a car to get around more easily`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ynQWrNWYKGCGJwov7"
        }}>{`명진전복 (Myeong Jin Jeon Bok)`}</a>{` - Abalone`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Jeju_Black_pig"
        }}>{`흑돼지 (Heuk Dweji)`}</a>{` - Black pig, local culinary speciality`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7kNVPV5XsE5hAWfr9"
        }}>{`한라산 국립공원 (Hallasan National Park)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/MJUKJ4TGPQ3BKTur8"
            }}>{`Yeongsil trail`}</a>{` - Doesn't go to the summit but the easiest and most scenic. Around 3-4 hours each way.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/rfj3RToicJSrCtm7A"
        }}>{`오설록 티 뮤지엄 (O’sulloc Tea Museum)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/YZnzTRdDEsGhobz57"
            }}>{`Innisfree Jeju House`}</a></li>
          <li parentName="ul">{`Soap workshop was fun`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qAkCmS5wxgtTzSbV8"
        }}>{`김녕미로공원 (Gimnyeong Maze Park)`}</a>{` - Not that big and wasn’t that challenging`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/TmAYP8PGeDMbA96H6"
        }}>{`소인국 테마파크 (Soingook Theme Park)`}</a>{` - Miniature park`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/rvyfmNK4rvBd5R1M7"
        }}>{`천제연폭포 (Cheonjeyeon Waterfalls)`}</a>{` - Waterfalls light up at night`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VVVm8w79QscT3u5J9"
        }}>{`테디베어박물관`}</a>{` - Teddy Bear Museum`}</li>
    </ul>
    <h4 {...{
      "id": "combo-ticket"
    }}>{`Combo ticket`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ksSsUr95JXEW2U93A"
        }}>{`산방굴사 (Sanbanggulsa Grotto)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/VZ7MPZ6mj8j7vBob6"
        }}>{`용머리해안 (Yongmeori Coast)`}</a>{` - Dragon Head Cliff`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Xqnf7pwhEeF6GboQ6"
        }}>{`만장굴 (Manjanggul Cave)`}</a>{` - Chilly inside since it’s a cave but not scary`}</li>
    </ul>
    <h2 {...{
      "id": "부산-busan"
    }}>{`부산 (Busan)`}</h2>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/eeBZScefvxYusngDA"
        }}>{`오반장 (Oh Banjang)`}</a>{` - Loved the outdoor KBBQ!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PrnUPWqdzwTkmitPA"
        }}>{`옥다방 (Okdabang)`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-3"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QdeNmskZr2oi9j2D6"
        }}>{`해동용궁사 (Haedong Yonggungsa Temple)`}</a>{` - Quite far on public transit but the seaside temple is beautiful`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/WYDCiaACW8jQ6Gn99"
        }}>{`태종대유원지 (Taejongdae Park)`}</a>{` - Ride the circular "Danubi Train” to enjoy the scenery`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gArsjKFW8UW1viUk99"
        }}>{`해운대해수욕장 (Haeundae Beach)`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/KgYqmmHSvPwgxZQ57"
            }}>{`Aquarium`}</a>{` right on the beach`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/bcWFTu5EdMR9Sz4x8"
            }}>{`Mermaid statue`}</a>{` - Along the way to the lighthouse`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/pFAkBt3aHb4JaFmHA"
            }}>{`동백섬 등대`}</a>{` - Dongbaekseom Lighthouse`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/AYiSXNxDPMG4VLV97"
        }}>{`광안리해수욕장 (Gwangalli Beach)`}</a>{` - Can see the `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/379genEPVvQQbjBQ6"
        }}>{`광안대교 (Gwangan Bridge)`}</a>{` light up at night`}</li>
    </ul>
    <h3 {...{
      "id": "찜질방-jimjilbang"
    }}>{`찜질방 (Jimjilbang)`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XDz7ep59RS1uCNwu8"
        }}>{`힐스파 (Hilseupa)`}</a>{` - Used to be called Vesta Spa...`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/JG9BzVC77fE4XzhLA"
        }}>{`스파랜드 (SPA LAND)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/v6TvKmUDwgUsyTbs6"
        }}>{`허심청온천탕 (Hurshimchung Spa)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/giFZ2jp67G3WXZMLA"
        }}>{`해운대온천센터 (Haeundae Spa Center)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      